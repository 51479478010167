var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:[
    'head-box  h100 w100 flex flex-cross--center',
    _setup.props.border ? 'table-head-line' : '',
  ]},[_vm._t("rowHead",function(){return [_c('div',{staticClass:"flex flex-cross--center flex-main--center"},[_c('a-checkbox',{style:({ width: `${_setup.selectBoxWidth}` }),attrs:{"id":`${_setup.props.preffixID}_material_all_choose_second_material_list_${_setup.props.rowIndex}_checkbox`},on:{"change":_setup.changeSelectAll},model:{value:(_setup.plank.isAllSelect),callback:function ($$v) {_vm.$set(_setup.plank, "isAllSelect", $$v)},expression:"plank.isAllSelect"}})],1),_c('div',{staticClass:"head-text flex flex-main--justify"},[_c('div',{staticClass:"plank-describe",attrs:{"id":`material_shrink_or_unfold_btn_${_setup.props.rowIndex}`},on:{"click":function($event){return _setup.changeFold(_setup.plank, _setup.props.rowIndex)}}},[_c('span',{class:[
            'fold-icon',
            'iconfont',
            _setup.plank.isUnfold ? 'icon-arrow' : 'icon-zhankai',
            'm0',
          ]}),_c('span',{staticClass:"title m0",staticStyle:{"color":"#333"}},[_vm._v(" "+_vm._s(_setup.plank.thick)+"mm"+_vm._s(_setup.plank.matCode)+"（"+_vm._s(_setup.plank.texture)+"） - ["+_vm._s(_setup.props.filterAmount(_setup.plank))+_vm._s(_vm.$t('common.unit'))+", "+_vm._s(_setup.props.filterArea(_setup.plank))+"m²] ")]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_setup.plank.high_plank),expression:"plank.high_plank"}],staticClass:"high-plank bold fs12"},[_vm._v(_vm._s(_vm.$t('common.heighGloss'))+" ")])])])]},{"plank":_setup.plank,"part":_setup.plank})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }